"use client";

import { useRouter } from "next/navigation";
import { ErrorDisplay } from "./error-display";

/**
 * Properties for the chat entry component.
 */
interface PageNotFoundProps {
  fallbackPath: string;
  text?: string;
}

/**
 * Component for displaying a chat entry.
 */
export const PageNotFound = (props: PageNotFoundProps) => {
  const router = useRouter();
  return <ErrorDisplay reset={() => router.push(props.fallbackPath)} />;
};
